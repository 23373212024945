import {IWixStatic} from '@wix/yoshi-flow-editor'
import {EditorSDK, ComponentRef} from '@wix/platform-editor-sdk'

export const getOrigCompRef = (Wix: IWixStatic, editorSDK: EditorSDK) => {
  const compId = Wix.Utils.getOrigCompId()
  return editorSDK.document.components.getById('token', {id: compId})
}

export const getComponentData = async (editorSDK: EditorSDK, compRef: ComponentRef) => {
  const appData = await editorSDK.document.controllers.getData('', {
    controllerRef: compRef,
  })
  return appData?.config ?? {}
}
