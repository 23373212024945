import {getFullLocale, isEditor, isMobile} from '../../../../commons/selectors/environment'
import {isDemo, isTemplate} from '../../../../commons/selectors/instance'
import {isCalendarLayout, getRecurringFilter} from '../selectors/settings'
import {isCalendarPageLoaded} from '../selectors/calendar-layout'
import {getRealEventsCount} from '../selectors/events'
import {CalendarLoading} from '../types/state'
import {createAsyncAction} from '../services/redux-toolkit'
import {LoadCalendarEventsResponse, LoadEventsResponse} from '../utils/api'
import {
  isSelectEventsManuallyEnabled,
  isShowReorderedEventsOnstageEnabled,
} from '../../../../commons/selectors/experiments'
import settingsParams from '../../settingsParams'
import {getStatusFilter} from '../../utils/status-filter'
import {resetCalendar} from './calendar-layout'

export const reloadEvents = createAsyncAction('RELOAD_EVENTS', (_, {getState, dispatch}) =>
  isCalendarLayout(getState().component) ? dispatch(resetCalendar(true)) : dispatch(loadEventsPage(0)),
)

export const loadEventsPage = createAsyncAction<LoadEventsResponse, number | void>(
  'LOAD_EVENTS_PAGE',
  (offset: number, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()
    const {membersEnabled, paidPlansEnabled} = state
    offset = offset ?? getRealEventsCount(state)
    const categoryId = state.component.byCategoryId?.categoryId
    let filter = categoryId ? state.component.byCategoryId.statusFilter : state.component.byStatus?.filter
    const byEventId = !!state.component.byEventId
    const recurringFilter = getRecurringFilter(state)
    const selectEventsManuallyEnabled = isSelectEventsManuallyEnabled(state)
    const showReorderedEventsOnstageEnabled = isShowReorderedEventsOnstageEnabled(state)
    const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number

    if ((selectEventsManuallyEnabled || showReorderedEventsOnstageEnabled) && filterTypeFromSettings !== undefined) {
      filter = getStatusFilter(filterTypeFromSettings)
    }

    return serverApi.loadEvents({
      offset,
      filter,
      byEventId,
      members: membersEnabled,
      paidPlans: paidPlansEnabled,
      locale: getFullLocale(state),
      categoryId,
      recurringFilter,
      filterType: filterTypeFromSettings,
      showReorderedEventsOnstage: showReorderedEventsOnstageEnabled,
    })
  },
)

interface LoadCalendarEventsParams {
  referenceDate?: string
  origin?: CalendarLoading
  fullReset?: boolean
}

export const loadCalendarEvents = createAsyncAction<LoadCalendarEventsResponse, LoadCalendarEventsParams>(
  'LOAD_CALENDAR_EVENTS',
  ({fullReset, referenceDate}, {getState, extra: {serverApi}}) => {
    const state = getState()

    if (!fullReset && referenceDate && isCalendarPageLoaded(state.calendarLayout, referenceDate)) {
      return
    }

    const {membersEnabled, paidPlansEnabled} = state
    const categoryId = state.component.byCategoryId?.categoryId
    const filter = categoryId ? state.component.byCategoryId.statusFilter : state.component.byStatus?.filter
    const byEventId = !!state.component.byEventId
    const showcase = isDemo(state) || isTemplate(state) || isEditor(state) || isMobile(state)

    return serverApi.loadCalendarEvents({
      referenceDate,
      filter,
      byEventId,
      members: membersEnabled,
      paidPlans: paidPlansEnabled,
      locale: getFullLocale(state),
      categoryId,
      showcase,
    })
  },
)
